
import authService from '@/services/auth/authService';
import SuccessCheck from '@/components/SuccessCheck.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    SuccessCheck,
  },
})
export default class AccountConfirmationForm extends Vue {
  public showSuccessMessage = false;
  public error: string | string[] = '';
  public active = false;
  public loading = true;

  created(): void {
    this.verifyAccount();
  }

  async verifyAccount(): Promise<void> {
    const { token } = this.$route.query;

    try {
      await authService.verifyAccount({ token: String(token) });
      this.showSuccessMessage = true;
    } catch (err) {
      const { data } = err.response;

      this.error = Array.isArray(data.message) ? data.message[0] : data.message;
      if (this.error) this.active = true;
    } finally {
      this.loading = false;
    }
  }
}
