
import { Component, Vue } from 'vue-property-decorator';
import AccountConfirmationForm from '@/components/AccountConfirmationForm.vue';

@Component({
  components: {
    AccountConfirmationForm,
  },
})
export default class SignIn extends Vue {}
